/* eslint-disable no-underscore-dangle */
import tw from 'twin.macro';
import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import dayjs from 'dayjs';

import { uniqBy, head, filter } from 'lodash';
import Seo from '../components/Seo';
import Layout from '../components/Layout';
import SanityImage from '../components/SanityImage';
import Events from '../components/home/Events';
import { Button, Paragraph } from '../components/atoms';

export const query = graphql`
  query EventsPageQuery {
    sanitySiteSettings {
      eventsBannerImage {
        asset {
          gatsbyImageData
        }
      }
    }
    allSanityEvent(sort: { fields: fromDate, order: DESC }) {
      edges {
        node {
          _id
          title
          place
          fromDate
          toDate
          email
        }
      }
    }
  }
`;

const EventsPage = ({ data }) => {
  const pageData = data.sanitySiteSettings;
  const allEvents = data.allSanityEvent.edges;
  const events =
    allEvents &&
    allEvents.map(({ node }) => ({
      ...node,
      year: dayjs(node.fromDate).format('YYYY'),
    }));
  const eventYears = uniqBy(events, 'year');
  const [selectedYear, setSelectedYear] = useState(
    eventYears.length > 0
      ? dayjs(head(eventYears).fromDate).format('YYYY')
      : dayjs(new Date()).format('YYYY'),
  );

  const selectedYearEvents = filter(
    allEvents,
    (o) => dayjs(o.node.fromDate).format('YYYY') === selectedYear,
  );
  return (
    <Layout>
      <Seo title="Schedule" />
      <div>
        {pageData.eventsBannerImage && (
          <SanityImage
            fluid={pageData.eventsBannerImage.asset.gatsbyImageData}
          />
        )}
        <div tw="text-center mt-10 max-w-xs mx-auto px-6">
          <Link to="/enrol">
            <Button isRounded isFullWidth>
              Enrol Now
            </Button>
          </Link>
        </div>
        <div tw="container mx-auto my-24">
          <div tw="my-6 flex justify-center px-4">
            <ul tw="flex cursor-pointer border-b lg:justify-center overflow-auto">
              {eventYears &&
                eventYears.map((item) => (
                  <li
                    key={item._id}
                    css={[
                      tw`rounded-t-lg`,
                      dayjs(item.fromDate).format('YYYY') === selectedYear &&
                        tw`bg-tertiary text-white`,
                    ]}
                  >
                    <button
                      tw="py-2 px-6"
                      type="button"
                      onClick={() =>
                        setSelectedYear(dayjs(item.fromDate).format('YYYY'))
                      }
                    >
                      {dayjs(item.fromDate).format('YYYY')}
                    </button>
                  </li>
                ))}
            </ul>
          </div>
          <div tw="text-center">
            <Paragraph center>
              All classes are listed in Indian standard time.
            </Paragraph>
          </div>
          <Events showEventsCardOnly events={selectedYearEvents} />
        </div>
      </div>
    </Layout>
  );
};

export default EventsPage;
