/* eslint-disable no-underscore-dangle */
import 'twin.macro';
import React from 'react';
import dayjs from 'dayjs';
import { Link } from 'gatsby';
import { Heading, Button, Paragraph } from '../atoms';
import config from '../../utils/config';

const Events = ({ events, showEventsCardOnly }) => (
  <div tw="container mx-auto my-16">
    {!showEventsCardOnly && (
      <Heading type="h1" center>
        Schedule
      </Heading>
    )}
    <div tw="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 md:gap-12 gap-4 mt-10 mx-4">
      {events.map(({ node }) => (
        <div
          key={node._id}
          tw="flex flex-row bg-white shadow-lg rounded-md p-4 my-2"
        >
          <div tw="flex flex-col flex-grow ml-4">
            <Heading type="h3" textSmall>
              {node.title}
            </Heading>
            <Paragraph tw="text-sm text-gray-500 my-1">
              Dates :{' '}
              {node.fromDate
                ? dayjs(node.fromDate).format('MM-DD-YYYY')
                : 'Not Available'}
              {node.toDate && `- ${dayjs(node.toDate).format('MM-DD-YYYY')}`}
            </Paragraph>
            {node.place && (
              <Paragraph tw="text-sm text-gray-500 my-1">
                Place: {node.place}
              </Paragraph>
            )}
            <Paragraph tw="text-sm text-gray-500  my-1">
              Email: {node.email ? node.email : config.email}
            </Paragraph>
          </div>
        </div>
      ))}
    </div>
    {!showEventsCardOnly && (
      <div tw="text-center mt-14">
        <Link to="/schedule">
          <Button>Show all schedule</Button>
        </Link>
      </div>
    )}
  </div>
);

export default Events;
