import React from 'react';
import 'twin.macro';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import config from '../utils/config';

const SanityImage = ({ alt, fluid }) => {
  const image = getImage(fluid);
  if (!image) {
    return null;
  }

  return (
    <GatsbyImage tw="object-cover" image={image} alt={alt || config.siteName} />
  );
};

export default SanityImage;

SanityImage.propTypes = {
  alt: PropTypes.string,
};

SanityImage.defaultProps = {
  alt: '',
};
